@tailwind base;
@tailwind components;
@tailwind utilities;


$mainColor: #dc3545;
/* ------ Custom Scroll bar -------- */

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #292524;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #57534e;
  border-radius: 9999px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #57534e;
}

/* --------------------------------- */


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.modal-token-item {
  display: grid;
  cursor: pointer;
  grid-template-columns: 40px minmax(auto, 1fr);
  align-items: center;
  -moz-column-gap: 1rem;
  column-gap: 1rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1.5rem;

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}


.main-container {
  background-image: url("../images/background.png");
  background-size: cover;

}